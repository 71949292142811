import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"

const TermsPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "terms" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "terms_title" })}
        sliderSubtitle={intl.formatMessage({ id: "terms_subtitle" })}
        objectPosition="30% 50%"

      />
      <Subscribe />
      <div className="termsPage">
        <h1>{intl.formatMessage({ id: "termsPage_title" })}</h1>

        <h2>{intl.formatMessage({ id: "termsPage_text1" })}</h2>
        <p>{intl.formatMessage({ id: "termsPage_text2" })}</p>
        <h2>{intl.formatMessage({ id: "termsPage_text3" })}</h2>
        <p>{intl.formatMessage({ id: "termsPage_text4" })}</p>
        <h2>{intl.formatMessage({ id: "termsPage_text5" })}</h2>
        <p>{intl.formatMessage({ id: "termsPage_text6" })}</p>
        <h2 style={{ marginBottom: "0" }}>
          {intl.formatMessage({ id: "termsPage_text7" })}
        </h2>
        <h4>{intl.formatMessage({ id: "termsPage_text8" })}</h4>
        <ol>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text9" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text10" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text11" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text12" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text13" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text14" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text15" })}</li>
          </p>
          <p>
            <li>{intl.formatMessage({ id: "termsPage_text16" })}</li>
          </p>
        </ol>
      </div>
    </Layout>
  )
}

export default injectIntl(TermsPage)
